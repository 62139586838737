import { useEffect, useState } from "react";
import style from "./Admin.module.css";
import Newproduct from "./Newproduct.component";
import { MdDelete, MdEdit } from "react-icons/md";
import { db } from "../../firebase";
import { collection, deleteDoc, doc,getDocs, query, where } from "firebase/firestore";
import UpdateProduct from "./Updateproduct.component";
export default function Productmanager({ showLoading }) {
  const [itemsfound, setItems] = useState([]);
  const [activeComponent , setActiveComponent] = useState({page:'',uid:''});
  const handleSearch = async (event) => {
    if(event.currentTarget.value ===''){
        const item = await getDocs(collection(db, "products"));
        setItems(item.docs.map(doc => ({
            id: doc.id, // Include the document ID
            ...doc.data() // Include the document data
        })));
        return;
    }
    if (event.key.toLowerCase() === "enter") {
        const productCollection = collection(db,'products');
        const q = query(productCollection,where('uid','==',event.currentTarget.value));
        
        const item = await getDocs(q);
        setItems(item.docs.map(doc => ({
            id: doc.id, // Include the document ID
            ...doc.data() // Include the document data
            })));
        return;
    } 
  };
  const addNewProduct = ()=>{
    setActiveComponent({page:'newproduct',uid:''});
  }
  const updateProduct = (event)=>{
    event.stopPropagation();
    setActiveComponent({page:'updateproduct',uid:event.currentTarget.getAttribute('data-uid')});
  }
  const deleteProduct = async (event) =>{
    event.stopPropagation();
    await deleteDoc(doc(db, "products",event.currentTarget.getAttribute('data-uid')));
    const item = await getDocs(collection(db, "products"));
    setItems(item.docs.map(doc => ({
        id: doc.id, // Include the document ID
        ...doc.data() // Include the document data
    })));
    return;
  }
  useEffect(() => {
    getDocs(collection(db, "products")).then((item)=>{
        setItems(item.docs.map(doc => ({
            id: doc.id, // Include the document ID
            ...doc.data() // Include the document data
        })));
    });
        
  }, [activeComponent]);
  const searchResult = (
    <div className={style.productmanager}>
      <div className={`${style.productactions} d-flex gap-2`}>
        <input
          type="text"
          onKeyUp={handleSearch}
          placeholder="Product UID "
        ></input>
        <button onClick={addNewProduct}>New</button>
      </div>
      <div className={style.productactioncontainer}>
        {itemsfound.map((item) => {
          return(
            <div className={`${style.founditem} d-flex`}>
            <div className="col-6 d-flex flex-column">
              <span>{item.uid}</span>
              <span>{item.name}</span>
              <span>{item.category}</span>
            </div>
            <div
              className={`${style.founditemaction} col-6 d-flex gap-3 justify-content-end`}
            >
              <span data-uid={item.uid} onClick={updateProduct}>
                <MdEdit></MdEdit>
              </span>
              <span data-uid={item.uid} onClick={deleteProduct}>
                <MdDelete></MdDelete>
              </span>
            </div>
          </div>
          )
        })}
        <span className={itemsfound.length===0?'d-flex':'d-none'}>No item found with the search value</span>
      </div>
    </div>
  );
  return <div>{activeComponent.page===''?searchResult:(activeComponent.page==='newproduct'?<Newproduct showLoading={showLoading} setActiveComponent={setActiveComponent}></Newproduct>:<UpdateProduct showLoading={showLoading} uid={activeComponent.uid} setActiveComponent={setActiveComponent}></UpdateProduct>)}</div>;
}
