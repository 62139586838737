import { useEffect, useRef, useState } from "react";
import style from "./Admin.module.css";
import { FaCheck, FaPlus,FaSave } from "react-icons/fa";
import { db ,storage} from "../../firebase";
import { doc ,getDocs,collection, setDoc } from "firebase/firestore";
import { ref,uploadBytes, getDownloadURL } from 'firebase/storage';
import { MdDelete } from "react-icons/md";

const addNewProduct = async (product)=>{
    try{
        const updatedJSON = await uploadPhotosSerially(product);
        await setDoc(doc(db, "products",product.uid), updatedJSON);
        alert('Data added');
    }catch(error){
        console.log(error);
    }
}
const uploadPhotosSerially = async (product) => {
    const downloadURLs = [];
  
    // Loop over the photos array and upload one by one
    for (let i = 0; i < product.photos.length; i++) {
      const photo = product.photos[i];
      const storageRef = ref(storage, `product/photo_${photo.name}`); // Create a reference for each image
      const snapshot = await uploadBytes(storageRef, photo); // Upload the file serially
      const downloadURL = await getDownloadURL(snapshot.ref); // Get the download URL
      downloadURLs.push({name:photo.name,downloadURL:downloadURL,isUploaded:true}); // Store the download URL in the array
    }
  
    // Restructure your original JSON to replace photo data with download URLs
    product.photos = [...downloadURLs];
  
    return {...product};
  };

export default function Newproduct({showLoading,setActiveComponent}){
    const [newproduct ,setFormData]= useState({
        uid:'',
        name:'',
        description:'',
        photos:[],
        productsizes:[],
        category:'',
        referenceId:''
    });
    const [category , setCategory] = useState([]);
    const formRef = useRef();
    const [selectedImagesSrc,setImagesSrc] = useState([]);
    const fileRef = useRef();
    const handleOnSubmit =async (event)=>{
        event.preventDefault();
        if(!newproduct.uid){
            alert('Enter product unique id');
            return;
        }
        if(!newproduct.name){
            alert('Enter product name');
            return;
        }
        if(!newproduct.description){
            alert('Enter product description');
            return;
        }
        if(newproduct.photos.length===0){
            alert('Selected one photos minimum');
            return;
        }
        if(newproduct.category===''){
            alert('Select a correct option');
            return;
        }
        if(newproduct.productsizes.length===0){
            alert('Select one size minimum');
            return;
        }
        showLoading(true);
        await addNewProduct(newproduct);
        showLoading(false);
        formRef.current.reset();
        setActiveComponent({page:''});
    }
    const cancel = (e)=>{
        e.preventDefault();
        setActiveComponent({page:''});
    }
    const handleFiletrigger = ()=>{
        showLoading(true);
        fileRef.current.click();
    }
    const handleInputtextchange =(e)=>{
        e.preventDefault();
        const { name, value } = e.target;
        setFormData({
          ...newproduct,  // Spread the previous formData
          [name]: value // Dynamically update the correct field
        });
    }
    const handlePhotoschange =(e)=>{
        e.preventDefault();
        const files = e.target.files;
        for(var i=0;i<files.length;i++){
            const file = files[i];
            const reader = new FileReader();
            reader.onload = (event) => {
                setImagesSrc((prevImageSrcs) => [...prevImageSrcs, event.target.result]); 
                showLoading(false);
            };
            
            reader.readAsDataURL(file);
        }
        setFormData({
            ...newproduct,
            "photos":[...newproduct.photos,...files]
        })
       
    }
    const handleSizechange =(e)=>{
        e.preventDefault();
        const value = e.currentTarget.getAttribute('data-value');
        const checked = e.currentTarget.getAttribute('data-checked');
        if(!checked){
            setFormData({
                ...newproduct,  // Spread the previous formData
                "productsizes": [...newproduct.productsizes,value] // Dynamically update the correct field
            });
        }else{
            setFormData({
                ...newproduct,  // Spread the previous formData
                "productsizes": newproduct.productsizes.includes(value) ? newproduct.productsizes.filter((item,i)=>item!==value):newproduct.productsizes // Dynamically update the correct field
            });
        }
    }
    const handleRemoveImage = (e)=>{
        e.stopPropagation();
        const span = e.target.closest('span');
        const index = parseInt(span.getAttribute('value')) || 0;
        const files = newproduct.photos.filter((item,i)=>{
            return index!==i
        });
        setFormData({
            ...newproduct,
            "photos":files
        })
        const imageSrcs = selectedImagesSrc.filter((item,i)=>{
            return index!==i;
        })
        setImagesSrc(imageSrcs);
    }
    const handleCategorySelect = (event)=>{
        setFormData({
            ...newproduct,
            category:event.currentTarget.value
        })
    }
    useEffect(()=>{
        getDocs(collection(db, "category")).then((item)=>{
            setCategory(item.docs.map(doc => ({
                id: doc.id, // Include the document ID
                ...doc.data() // Include the document data
            })));
        });
    })
    return(
        <form ref={formRef} className={`${style.newproduct} d-flex flex-column`} onSubmit={handleOnSubmit}>
            <input type="text" name="uid" placeholder="Enter Unique identifier" onChange={handleInputtextchange}></input>
            <input type="text" name="name" placeholder="Enter Title" onChange={handleInputtextchange}></input>
            <input type="text" name="referenceid" placeholder="Enter Reference Id" onChange={handleInputtextchange}></input>
            <textarea name="description" placeholder=" Enter Description" onChange={handleInputtextchange} rows={10}></textarea>
            <select name='category' onChange={handleCategorySelect}>
                <option value={''}>Select an category</option>
                {
                    category.map((item)=>{
                        return(
                            <option value={item.uid}>{item.uid}</option>
                        )
                    })
                }
            </select>
            <div className={`${style.fileupload} d-flex flex-column`} onClick={handleFiletrigger}>
                <div className={`${selectedImagesSrc.length>0?'d-flex':'d-none'} gap-2`}>
                   {
                    selectedImagesSrc.map((src,index)=>{
                       return(
                            <div key={index} className={`${style.selectedimage}`}>
                                <img src={src} alt="alt"></img>
                                <span className="align-self-end" value={index} onClick={handleRemoveImage}><MdDelete></MdDelete> </span>
                            </div>
                       ) 
                    })
                   }
                </div>
                <span className={`${selectedImagesSrc.length>0?'mt-3':''}`}><FaPlus></FaPlus> Upload photos</span>
                <input ref={fileRef} className="d-none" type="file" onChange={handlePhotoschange} multiple accept="image/png, image/jpeg"></input>
            </div>
            <div className={`${style.productsizes}`}>
                <span>Selected avaialble sizes</span>
                <div className={`${style.sizes} mt-2`}>
                    <span className="d-flex gap-2"><span data-value={'xs'} data-checked={newproduct.productsizes.indexOf('xs')!==-1?true:''} onClick={handleSizechange} className={`${style.customcCheckbox} ${newproduct.productsizes.indexOf('xs')!==-1?style.checked:''}`}><FaCheck></FaCheck></span><span>Extra small</span></span>
                    <span className="d-flex gap-2"><span data-value={'s'} data-checked={newproduct.productsizes.indexOf('s')!==-1?true:''}  onClick={handleSizechange} className={`${style.customcCheckbox} ${newproduct.productsizes.indexOf('s')!==-1?style.checked:''}`}><FaCheck></FaCheck></span><span>Small</span></span>
                    <span className="d-flex gap-2"><span data-value={'m'} data-checked={newproduct.productsizes.indexOf('m')!==-1?true:''}  onClick={handleSizechange} className={`${style.customcCheckbox} ${newproduct.productsizes.indexOf('m')!==-1?style.checked:''}`}><FaCheck></FaCheck></span><span>Medium</span></span>
                    <span className="d-flex gap-2"><span data-value={'l'} data-checked={newproduct.productsizes.indexOf('l')!==-1?true:''}  onClick={handleSizechange} className={`${style.customcCheckbox} ${newproduct.productsizes.indexOf('l')!==-1?style.checked:''}`}><FaCheck></FaCheck></span><span>Large</span></span>
                    <span className="d-flex gap-2"><span data-value={'xl'} data-checked={newproduct.productsizes.indexOf('xl')!==-1?true:''}  onClick={handleSizechange} className={`${style.customcCheckbox} ${newproduct.productsizes.indexOf('xl')!==-1?style.checked:''}`}><FaCheck></FaCheck></span><span>Extra Large</span></span>
                </div>
            </div>
            <div className="d-flex">
                <button><FaSave></FaSave>Save</button>
                <button onClick={cancel}><FaSave></FaSave>Cancel</button>
            </div>
        </form>
    )
}