import { useEffect, useRef, useState } from "react";
import style from "./Dashboard.module.css";
import { FaPlus,FaSave } from "react-icons/fa";
import { db ,storage} from "../../firebase";
import { doc ,getDoc,setDoc } from "firebase/firestore";
import { ref,uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { MdDelete } from "react-icons/md";

const updateProduct = async (dashboard,deletedPhotos)=>{
    try{
        deletePhotoSerially(deletedPhotos);
        const updatedJSON = await uploadPhotosSerially(dashboard);
        await setDoc(doc(db, "dashboard",dashboard.uid), updatedJSON);
        alert('Data added');
    }catch(error){
        console.log(error);
    }
}
const deletePhotoSerially =  async (photos) => {
    for (let i = 0; i < photos.length; i++) {
      const photo = photos[i];
      if(photo.isUploaded){
        try{
            const storageRef = ref(storage, `dashboard/photo_${photo.name}`); // Create a reference for each image
            await deleteObject(storageRef);
        }catch(e){
            console.log(e);
        } // Upload the file serially
      }
    }
  };
const uploadPhotosSerially = async (dashboard) => {
    const downloadURLs = [];
  
    
    for (let i = 0; i < dashboard.photos.length; i++) {
      const photo = dashboard.photos[i];
      if(photo.isUploaded){
        downloadURLs.push({name:photo.name,downloadURL:photo.downloadURL,isUploaded:true});
        continue;
      }
      const storageRef = ref(storage, `dashboard/photo_${photo.file.name}`); // Create a reference for each image
      const snapshot = await uploadBytes(storageRef, photo.file); // Upload the file serially
      const downloadURL = await getDownloadURL(snapshot.ref); // Get the download URL
      downloadURLs.push({name:photo.file.name,downloadURL:downloadURL,isUploaded:true}); // Store the download URL in the array
    }
  
    // Restructure your original JSON to replace photo data with download URLs
    dashboard.photos = [...downloadURLs];
  
    return {...dashboard};
  };

export default function Updatedashboard({uid,showLoading,setActiveComponent}){
    const [deletedImage , setDeletedImage] = useState([]);
    const [dashboard ,setFormData]= useState({
        uid:'',
        photos:[],
        productsizes:[],
    });
    const formRef = useRef();
    const [selectedImagesSrc,setImagesSrc] = useState([]);
    const fileRef = useRef();
    const handleOnSubmit =async (event)=>{
        event.preventDefault();
        if(!dashboard.uid){
            alert('Enter dashboard unique id');
            return;
        }
        if(dashboard.photos.length===0){
            alert('Selected one photos minimum');
            return;
        }
       
        showLoading(true);
        await updateProduct(dashboard,deletedImage);
        showLoading(false);
        formRef.current.reset();
        setActiveComponent({page:''});
    }
    const handleFiletrigger = ()=>{
        fileRef.current.click();
    }
    const handleInputtextchange =(e)=>{
        e.preventDefault();
        const { name, value } = e.target;
        setFormData({
          ...dashboard,  // Spread the previous formData
          [name]: value // Dynamically update the correct field
        });
    }
    const handlePhotoschange =(e)=>{
        e.preventDefault();
        const files = e.target.files;
        if(files.length===0){
            showLoading(false);
            return;
        }
        showLoading(true);
        for(var i=0;i<files.length;i++){
            const file = files[i];
            let photoObj = {isUploaded:false,downloadURL:'',file:file};
            const reader = new FileReader();
            reader.onload = (event) => {
                photoObj['downloadURL']=event.target.result;
                setImagesSrc((prevImageSrcs) => [...prevImageSrcs, photoObj]); 
                setFormData({
                    ...dashboard,
                    "photos":[...dashboard.photos,photoObj]
                })
                showLoading(false);
            };
            reader.readAsDataURL(file);
        }
    }
    
    const handleRemoveImage = (e)=>{
        e.stopPropagation();
        const span = e.target.closest('span');
        const index = parseInt(span.getAttribute('value')) || 0;
        const isUploaded = e.currentTarget.getAttribute('data-isuploaded');
        if(isUploaded){
            setDeletedImage([...deletedImage,dashboard.photos[index]]);
        }
        const files = dashboard.photos.filter((item,i)=>{
            return index!==i
        });
        setFormData({
            ...dashboard,
            "photos":files
        })
    }
    const cancel = (e)=>{
        e.preventDefault();
        setActiveComponent({page:''});
    }
    useEffect(()=>{
        const fetchItems = async ()=>{
            const item = await getDoc(doc(db, "dashboard",uid));
            setFormData({...item.data()})
        }
        fetchItems();
    })
    return(
        <form ref={formRef} className={`${style.dashboard} d-flex flex-column`} onSubmit={handleOnSubmit}>
            <input type="text" name="uid" placeholder="Unique identifier" value={dashboard.uid} onChange={handleInputtextchange}></input>
            <div className={`${style.fileupload} d-flex flex-column`} onClick={handleFiletrigger}>
                <div className={`${selectedImagesSrc.length>0 || dashboard.photos.length>0?'d-flex':'d-none'} gap-2`}>
                   {
                    dashboard.photos.map((src,index)=>{
                        return( 
                             <div key={index} className={`${style.selectedimage}`}>
                                 <img src={src.downloadURL} alt="alt"></img>
                                 <span className="align-self-end" value={index} data-isuploaded={src.isUploaded?true:''} onClick={handleRemoveImage}><MdDelete></MdDelete> </span>
                             </div>
                        ) 
                     })
                   }
                </div>
                <span className={`${selectedImagesSrc.length>0?'mt-3':''}`}><FaPlus></FaPlus> Upload photos</span>
                <input ref={fileRef} className="d-none" type="file" onChange={handlePhotoschange} multiple accept="image/png, image/jpeg"></input>
            </div>
            <div className="d-flex">
                <button type="submit"><FaSave></FaSave>Save</button>
                <button type="button" onClick={cancel}><FaSave></FaSave>Cancel</button>
            </div>
        </form>
    )
}