import { useEffect, useRef, useState } from "react";
import style from "./Admin.module.css";
import {  FaCheck, FaPlus,FaSave } from "react-icons/fa";
import { db ,storage} from "../../firebase";
import { doc ,getDoc,getDocs,collection,setDoc } from "firebase/firestore";
import { ref,uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { MdDelete } from "react-icons/md";

const updateProduct = async (product,deletedPhotos)=>{
    try{
        deletePhotoSerially(deletedPhotos);
        const updatedJSON = await uploadPhotosSerially(product);
        await setDoc(doc(db, "products",product.uid), updatedJSON);
        alert('Data added');
    }catch(error){
        console.log(error);
    }
}
const deletePhotoSerially =  async (photos) => {
    for (let i = 0; i < photos.length; i++) {
      const photo = photos[i];
      if(photo.isUploaded){
        const storageRef = ref(storage, `product/photo_${photo.name}`); // Create a reference for each image
        await deleteObject(storageRef); // Upload the file serially
      }
    }
  };
const uploadPhotosSerially = async (product) => {
    const downloadURLs = [];
  
    
    for (let i = 0; i < product.photos.length; i++) {
      const photo = product.photos[i];
      if(photo.isUploaded){
        downloadURLs.push({name:photo.name,downloadURL:photo.downloadURL,isUploaded:true});
        continue;
      }
      const storageRef = ref(storage, `product/photo_${photo.file.name}`); // Create a reference for each image
      const snapshot = await uploadBytes(storageRef, photo.file); // Upload the file serially
      const downloadURL = await getDownloadURL(snapshot.ref); // Get the download URL
      downloadURLs.push({name:photo.file.name,downloadURL:downloadURL,isUploaded:true}); // Store the download URL in the array
    }
  
    // Restructure your original JSON to replace photo data with download URLs
    product.photos = [...downloadURLs];
  
    return {...product};
  };

export default function UpdateProduct({uid,showLoading,setActiveComponent}){
    const [deletedImage , setDeletedImage] = useState([]);
    const [product ,setFormData]= useState({
        uid:'',
        name:'',
        description:'',
        photos:[],
        productsizes:[],
        category:'',
        referenceId:''
    });
    const formRef = useRef();
    const [category , setCategory] = useState([]);
    const [selectedImagesSrc,setImagesSrc] = useState([]);
    const fileRef = useRef();
    const handleOnSubmit =async (event)=>{
        event.preventDefault();
        if(!product.uid){
            alert('Enter product unique id');
            return;
        }
        if(!product.name){
            alert('Enter product name');
            return;
        }
        if(!product.description){
            alert('Enter product description');
            return;
        }
        if(product.category==='' || !product.category){
            alert('Select a correct option');
            return;
        }
        if(product.photos.length===0){
            alert('Selected one photos minimum');
            return;
        }
        if(product.productsizes.length===0){
            alert('Select one size minimum');
            return;
        }
        showLoading(true);
        await updateProduct(product,deletedImage);
        showLoading(false);
        formRef.current.reset();
        setActiveComponent({page:''});
    }
    const handleFiletrigger = ()=>{
        fileRef.current.click();
    }
    const handleInputtextchange =(e)=>{
        e.preventDefault();
        const { name, value } = e.target;
        setFormData({
          ...product,  // Spread the previous formData
          [name]: value // Dynamically update the correct field
        });
    }
    const handlePhotoschange =(e)=>{
        e.preventDefault();
        const files = e.target.files;
        if(files.length===0){
            showLoading(false);
            return;
        }
        showLoading(true);
        for(var i=0;i<files.length;i++){
            const file = files[i];
            let photoObj = {isUploaded:false,downloadURL:'',file:file};
            const reader = new FileReader();
            reader.onload = (event) => {
                photoObj['downloadURL']=event.target.result;
                setImagesSrc((prevImageSrcs) => [...prevImageSrcs, photoObj]); 
                setFormData({
                    ...product,
                    "photos":[...product.photos,photoObj]
                })
                showLoading(false);
            };
            reader.readAsDataURL(file);
        }
        
       
    }
    const handleSizechange =(e)=>{
        e.preventDefault();
        const value = e.currentTarget.getAttribute('data-value');
        const checked = e.currentTarget.getAttribute('data-checked');
        if(!checked){
            setFormData({
                ...product,  // Spread the previous formData
                "productsizes": [...product.productsizes,value] // Dynamically update the correct field
            });
        }else{
            setFormData({
                ...product,  // Spread the previous formData
                "productsizes": product.productsizes.includes(value) ? product.productsizes.filter((item,i)=>item!==value):product.productsizes // Dynamically update the correct field
            });
        }
    }
    const handleRemoveImage = (e)=>{
        e.stopPropagation();
        const span = e.target.closest('span');
        const index = parseInt(span.getAttribute('value')) || 0;
        const isUploaded = e.currentTarget.getAttribute('data-isuploaded');
        if(isUploaded){
            setDeletedImage([...deletedImage,product.photos[index]]);
        }
        const files = product.photos.filter((item,i)=>{
            return index!==i
        });
        setFormData({
            ...product,
            "photos":files
        })
    }
    const cancel = (e)=>{
        e.preventDefault();
        setActiveComponent({page:''});
    }
    const handleCategorySelect = (event)=>{
        setFormData({
            ...product,
            category:event.currentTarget.value
        })
    }
    useEffect(()=>{
        const fetchItems = async ()=>{
            const item = await getDoc(doc(db, "products",uid));
            getDocs(collection(db, "category")).then((categories)=>{
                setCategory(categories.docs.map(doc => ({
                    id: doc.id, // Include the document ID
                    ...doc.data() // Include the document data
                })));
                setFormData({...item.data()})
            });
        }
        fetchItems();
    })
    return(
        <form ref={formRef} className={`${style.product} d-flex flex-column`} onSubmit={handleOnSubmit}>
            <input type="text" name="uid" placeholder="Unique identifier" value={product.uid} onChange={handleInputtextchange}></input>
            <input type="text" name="name" placeholder="title" value={product.name} onChange={handleInputtextchange}></input>
            <input type="text" name="referenceid" placeholder="Enter Reference Id" onChange={handleInputtextchange}></input>
            <textarea name="description" placeholder="description"value={product.description} onChange={handleInputtextchange} rows={10}></textarea>
            <select name='category' onChange={handleCategorySelect}>
                <option value={''} selected={product.category===''?true:false}>Select a category</option>
                {
                    category.map((item)=>{
                        return(
                            <option value={item.uid} selected={product.category===item.uid?true:false}>{item.uid}</option>
                        )
                    })
                }
            </select>
            <div className={`${style.fileupload} d-flex flex-column`} onClick={handleFiletrigger}>
                <div className={`${selectedImagesSrc.length>0 || product.photos.length>0?'d-flex':'d-none'} gap-2`}>
                   {
                    product.photos.map((src,index)=>{
                        return( 
                             <div key={index} className={`${style.selectedimage}`}>
                                 <img src={src.downloadURL} alt="alt"></img>
                                 <span className="align-self-end" value={index} data-isuploaded={src.isUploaded?true:''} onClick={handleRemoveImage}><MdDelete></MdDelete> </span>
                             </div>
                        ) 
                     })
                   }
                </div>
                <span className={`${selectedImagesSrc.length>0?'mt-3':''}`}><FaPlus></FaPlus> Upload photos</span>
                <input ref={fileRef} className="d-none" type="file" onChange={handlePhotoschange} multiple accept="image/png, image/jpeg"></input>
            </div>
            <div className={`${style.productsizes}`}>
                <span>Selected avaialble sizes</span>
                <div className={`${style.sizes} mt-2`}>
                    <span className="d-flex gap-2"><span data-value={'xs'} data-checked={product.productsizes.indexOf('xs')!==-1?true:''} onClick={handleSizechange} className={`${style.customcCheckbox} ${product.productsizes.indexOf('xs')!==-1?style.checked:''}`}><FaCheck></FaCheck></span><span>Extra small</span></span>
                    <span className="d-flex gap-2"><span data-value={'s'} data-checked={product.productsizes.indexOf('s')!==-1?true:''}  onClick={handleSizechange} className={`${style.customcCheckbox} ${product.productsizes.indexOf('s')!==-1?style.checked:''}`}><FaCheck></FaCheck></span><span>Small</span></span>
                    <span className="d-flex gap-2"><span data-value={'m'} data-checked={product.productsizes.indexOf('m')!==-1?true:''}  onClick={handleSizechange} className={`${style.customcCheckbox} ${product.productsizes.indexOf('m')!==-1?style.checked:''}`}><FaCheck></FaCheck></span><span>Medium</span></span>
                    <span className="d-flex gap-2"><span data-value={'l'} data-checked={product.productsizes.indexOf('l')!==-1?true:''}  onClick={handleSizechange} className={`${style.customcCheckbox} ${product.productsizes.indexOf('l')!==-1?style.checked:''}`}><FaCheck></FaCheck></span><span>Large</span></span>
                    <span className="d-flex gap-2"><span data-value={'xl'} data-checked={product.productsizes.indexOf('xl')!==-1?true:''}  onClick={handleSizechange} className={`${style.customcCheckbox} ${product.productsizes.indexOf('xl')!==-1?style.checked:''}`}><FaCheck></FaCheck></span><span>Extra Large</span></span>
                </div>
            </div>
            <div className="d-flex">
                <button type="submit"><FaSave></FaSave>Save</button>
                <button type="button" onClick={cancel}><FaSave></FaSave>Cancel</button>
            </div>
        </form>
    )
}