import style from "./Loading.module.css";

const Loading = () => {
  return (
    <div className={style.App}>
        <div className={style.loadingContainer}>
          <div className={style.spinner}></div>
          <p>Loading...</p>
        </div>
    </div>
  );
};

export default Loading;
