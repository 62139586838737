import { useState } from "react";
import style from "./Admin.module.css";
export default function Adminsidebar({setActivesidetab}){
    const [activeTab,setActiveTab] = useState('product');
    const handleActive =(event)=>{
        setActivesidetab(event.currentTarget.getAttribute('name'));
        setActiveTab(event.currentTarget.getAttribute('name'));
    }
    return (
        <div className={`${style.adminsidebarcontainer} col-3`}>
            <ul>
                <li name='product' onClick={handleActive} className={`${activeTab==='product'?style.active:''}`}>Product Mangement</li>
                <li name='category' onClick={handleActive} className={`${activeTab==='category'?style.active:''}`}>Cateogry Management</li>
                <li name='dashboard' onClick={handleActive} className={`${activeTab==='dashboard'?style.active:''}`}>Dashboard Management</li>
                {/* <li name='users' onClick={handleActive} className={`${activeTab==='users'?style.active:''}`}>Users Management</li> */}
            </ul>
        </div>
    )
}