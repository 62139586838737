import { useState } from "react";
import Navbar from "../Navbar/Navbar.component";
import Adminsidebar from "./Adminsidebar.component";
import Loading from "../Loading/Loading.component";
import Productmanager from "./Productmanager.component";
import style from './Admin.module.css';
import CategoryManager from "../CategoryManagement/Category.component";
import Dashboardmanager from "../Dashboard/Dasboardmanager.component";
export  default function Admin(){
    const [showLoading,setLoading] = useState(false);
    const handleShowLoading = (flag)=>{
        setLoading(flag);
    }
    const [activeSidetab , setActivesidetab] = useState('product');
    return(
        <div className={`${style.admincontainer} col-12`}>
            <Navbar></Navbar>
            {showLoading?<Loading></Loading>:''}
            <div className={`${style.manager} d-flex justify-content-around mt-5`}>
                <Adminsidebar setActivesidetab={setActivesidetab}></Adminsidebar>
                <div className="col-8">
                    {
                        activeSidetab==='product'?<Productmanager showLoading={handleShowLoading}></Productmanager>:
                        (activeSidetab === 'category'?<CategoryManager showLoading={handleShowLoading}></CategoryManager>:
                            (activeSidetab === 'dashboard'?<Dashboardmanager showLoading={handleShowLoading}></Dashboardmanager>:'')
                        )
                    }
                </div>
            </div>
        </div>
    )
}