import { useRef, useState } from "react";
import style from "./Dashboard.module.css";
import {  FaPlus,FaSave } from "react-icons/fa";
import { db ,storage} from "../../firebase";
import { doc ,setDoc } from "firebase/firestore";
import { ref,uploadBytes, getDownloadURL } from 'firebase/storage';
import { MdDelete } from "react-icons/md";

const addNewCategory = async (dashboard)=>{
    try{
        const updatedJSON = await uploadPhotosSerially(dashboard);
        await setDoc(doc(db, "dashboard",dashboard.uid), updatedJSON);
        alert('Data added');
    }catch(error){
        console.log(error);
    }
}
const uploadPhotosSerially = async (dashboard) => {
    const downloadURLs = [];
  
    // Loop over the photos array and upload one by one
    for (let i = 0; i < dashboard.photos.length; i++) {
      const photo = dashboard.photos[i];
      const storageRef = ref(storage, `dashboard/photo_${photo.name}`); // Create a reference for each image
      const snapshot = await uploadBytes(storageRef, photo); // Upload the file serially
      const downloadURL = await getDownloadURL(snapshot.ref); // Get the download URL
      downloadURLs.push({name:photo.name,downloadURL:downloadURL,isUploaded:true}); // Store the download URL in the array
    }
  
    // Restructure your original JSON to replace photo data with download URLs
    dashboard.photos = [...downloadURLs];
  
    return {...dashboard};
  };

export default function Newdashboard({showLoading,setActiveComponent}){
    const [newDashboard ,setFormData]= useState({
        uid:'',
        photos:[],
    });
    const formRef = useRef();
    const [selectedImagesSrc,setImagesSrc] = useState([]);
    const fileRef = useRef();
    const handleOnSubmit =async (event)=>{
        event.preventDefault();
        if(!newDashboard.uid){
            alert('Enter dashboard unique id');
            return;
        }
        if(newDashboard.photos.length===0){
            alert('Selected one photos minimum');
            return;
        }
        showLoading(true);
        await addNewCategory(newDashboard);
        showLoading(false);
        formRef.current.reset();
        setActiveComponent({page:''});
    }
    const cancel = (e)=>{
        e.preventDefault();
        setActiveComponent({page:''});
    }
    const handleFiletrigger = ()=>{
        fileRef.current.click();
    }
    const handleInputtextchange =(e)=>{
        e.preventDefault();
        const { name, value } = e.target;
        setFormData({
          ...newDashboard,  // Spread the previous formData
          [name]: value // Dynamically update the correct field
        });
    }
    const handlePhotoschange =(e)=>{
        e.preventDefault();
        const files = e.target.files;
        const file = files[0];
        const reader = new FileReader();
        showLoading(true);
        reader.onload = (event) => {
            setImagesSrc([event.target.result]); 
            showLoading(false);
        };
        
        reader.readAsDataURL(file);
        setFormData({
            ...newDashboard,
            "photos":[...files]
        })
       
    }
    const handleRemoveImage = (e)=>{
        e.stopPropagation();
        const span = e.target.closest('span');
        const index = parseInt(span.getAttribute('value')) || 0;
        const files = newDashboard.photos.filter((item,i)=>{
            return index!==i
        });
        setFormData({
            ...newDashboard,
            "photos":files
        })
        const imageSrcs = selectedImagesSrc.filter((item,i)=>{
            return index!==i;
        })
        setImagesSrc(imageSrcs);
    }
    return(
        <form ref={formRef} className={`${style.newDashboard} d-flex flex-column`} onSubmit={handleOnSubmit}>
            <input type="text" name="uid" placeholder="Unique identifier" onChange={handleInputtextchange}></input>
            <div className={`${style.fileupload} d-flex flex-column`} onClick={handleFiletrigger}>
                <div className={`${selectedImagesSrc.length>0?'d-flex':'d-none'} gap-2`}>
                   {
                    selectedImagesSrc.map((src,index)=>{
                       return(
                            <div key={index} className={`${style.selectedimage}`}>
                                <img src={src} alt="alt"></img>
                                <span className="align-self-end" value={index} onClick={handleRemoveImage}><MdDelete></MdDelete> </span>
                            </div>
                       ) 
                    })
                   }
                </div>
                <span className={`${selectedImagesSrc.length>0?'mt-3':''}`}><FaPlus></FaPlus> Upload photos</span>
                <input ref={fileRef} className="d-none" type="file" onChange={handlePhotoschange} accept="image/png, image/jpeg"></input>
            </div>
            <div className="d-flex">
                <button><FaSave></FaSave>Save</button>
                <button onClick={cancel}><FaSave></FaSave>Cancel</button>
            </div>
        </form>
    )
}