import './App.css';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Admin from './Components/Admin/Admin.component';
import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import Cookies from 'js-cookie';
function App() {
  const [isVerified,setIsVerified] = useState(false);
  const verifyPasscode = async () => {
    // Check if the passcode is already stored in cookies
    const storedPasscode = Cookies.get('userPasscode');

    if (storedPasscode) {
      // If a passcode exists in cookies, verify it directly without prompting
      const passcodeDoc = await getDoc(doc(db,'passcode','admin'));
      if (passcodeDoc.exists && storedPasscode === passcodeDoc.data().passcode) {
        setIsVerified(true);
        return; // Exit if the passcode is valid
      } else {
        // If the passcode doesn't match, remove the cookie and re-prompt
        Cookies.remove('userPasscode');
      }
    }

    // If there's no valid passcode in cookies, prompt the user for a new passcode
    const userPasscode = prompt("Enter your passcode to continue:");
    if (userPasscode) {
      try {
        // Fetch the stored passcode from Firestore
        const passcodeDoc = await getDoc(doc(db,'passcode','admin'));
        
        if (passcodeDoc.exists) {
          const storedPasscodeInDB = passcodeDoc.data().passcode;

          // Check if the entered passcode matches the stored one
          if (userPasscode === storedPasscodeInDB) {
            // Store the valid passcode in cookies with a 1-hour expiration
            Cookies.set('userPasscode', userPasscode, { expires: 1 / 24 }); // 1 hour = 1/24 of a day
            setIsVerified(true);

          } else {
            verifyPasscode(); // Re-prompt the user if the passcode is incorrect
          }
        } else {
          setIsVerified(false);
          alert('Passcode does not exist in the system.');
        }
      } catch (error) {
        setIsVerified(false);
        console.error("Error verifying passcode: ", error);
      }
    }
  };
  useEffect(() => {
    // Call the function to verify the passcode on app load
    verifyPasscode();
  });
  return (
    <div className="App d-flex flex-column justify-content-center align-items-center">
      {isVerified?<Admin></Admin>:<div className='d-flex justify-content-center align-items-center' style={{width:'100%',height:'100vh'}}><h1 >No access <span onClick={verifyPasscode} style={{color:'blue',cursor:"pointer",fontSize:'18px'}}>Click here to verify</span></h1></div>}
    </div>
  );
}

export default App;
