import { useRef, useState } from "react";
import style from "./Category.module.css";
import {  FaPlus,FaSave } from "react-icons/fa";
import { db ,storage} from "../../firebase";
import { doc ,setDoc } from "firebase/firestore";
import { ref,uploadBytes, getDownloadURL } from 'firebase/storage';
import { MdDelete } from "react-icons/md";

const addNewCategory = async (category)=>{
    try{
        const updatedJSON = await uploadPhotosSerially(category);
        await setDoc(doc(db, "category",category.uid), updatedJSON);
        alert('Data added');
    }catch(error){
        console.log(error);
    }
}
const uploadPhotosSerially = async (category) => {
    const downloadURLs = [];
  
    // Loop over the photos array and upload one by one
    for (let i = 0; i < category.photos.length; i++) {
      const photo = category.photos[i];
      const storageRef = ref(storage, `category/photo_${photo.name}`); // Create a reference for each image
      const snapshot = await uploadBytes(storageRef, photo); // Upload the file serially
      const downloadURL = await getDownloadURL(snapshot.ref); // Get the download URL
      downloadURLs.push({name:photo.name,downloadURL:downloadURL,isUploaded:true}); // Store the download URL in the array
    }
  
    // Restructure your original JSON to replace photo data with download URLs
    category.photos = [...downloadURLs];
  
    return {...category};
  };

export default function Newcategory({showLoading,setActiveComponent}){
    const [newCategory ,setFormData]= useState({
        uid:'',
        name:'',
        description:'',
        photos:[],
    });
    const formRef = useRef();
    const [selectedImagesSrc,setImagesSrc] = useState([]);
    const fileRef = useRef();
    const handleOnSubmit =async (event)=>{
        event.preventDefault();
        if(!newCategory.uid){
            alert('Enter category unique id');
            return;
        }
        if(!newCategory.name){
            alert('Enter category name');
            return;
        }
        if(!newCategory.description){
            alert('Enter category description');
            return;
        }
        if(newCategory.photos.length===0){
            alert('Selected one photos minimum');
            return;
        }
        showLoading(true);
        await addNewCategory(newCategory);
        showLoading(false);
        formRef.current.reset();
        setActiveComponent({page:''});
    }
    const cancel = (e)=>{
        e.preventDefault();
        setActiveComponent({page:''});
    }
    const handleFiletrigger = ()=>{
        fileRef.current.click();
    }
    const handleInputtextchange =(e)=>{
        e.preventDefault();
        const { name, value } = e.target;
        setFormData({
          ...newCategory,  // Spread the previous formData
          [name]: value // Dynamically update the correct field
        });
    }
    const handlePhotoschange =(e)=>{
        e.preventDefault();
        const files = e.target.files;
        for(var i=0;i<files.length;i++){
            const file = files[i];
            const reader = new FileReader();
            showLoading(true);
            reader.onload = (event) => {
                setImagesSrc((prevImageSrcs) => [...prevImageSrcs, event.target.result]); 
                showLoading(false);
            };
            
            reader.readAsDataURL(file);
        }
        setFormData({
            ...newCategory,
            "photos":[...newCategory.photos,...files]
        })
       
    }
    const handleRemoveImage = (e)=>{
        e.stopPropagation();
        const span = e.target.closest('span');
        const index = parseInt(span.getAttribute('value')) || 0;
        const files = newCategory.photos.filter((item,i)=>{
            return index!==i
        });
        setFormData({
            ...newCategory,
            "photos":files
        })
        const imageSrcs = selectedImagesSrc.filter((item,i)=>{
            return index!==i;
        })
        setImagesSrc(imageSrcs);
    }
    return(
        <form ref={formRef} className={`${style.newCategory} d-flex flex-column`} onSubmit={handleOnSubmit}>
            <input type="text" name="uid" placeholder="Unique identifier" onChange={handleInputtextchange}></input>
            <input type="text" name="name" placeholder="Enter Category name" onChange={handleInputtextchange}></input>
            <textarea name="description" placeholder="Enter Category description" onChange={handleInputtextchange} rows={10}></textarea>
            <div className={`${style.fileupload} d-flex flex-column`} onClick={handleFiletrigger}>
                <div className={`${selectedImagesSrc.length>0?'d-flex':'d-none'} gap-2`}>
                   {
                    selectedImagesSrc.map((src,index)=>{
                       return(
                            <div key={index} className={`${style.selectedimage}`}>
                                <img src={src} alt="alt"></img>
                                <span className="align-self-end" value={index} onClick={handleRemoveImage}><MdDelete></MdDelete> </span>
                            </div>
                       ) 
                    })
                   }
                </div>
                <span className={`${selectedImagesSrc.length>0?'mt-3':''}`}><FaPlus></FaPlus> Upload photos</span>
                <input ref={fileRef} className="d-none" type="file" onChange={handlePhotoschange} multiple accept="image/png, image/jpeg"></input>
            </div>
            <div className="d-flex">
                <button><FaSave></FaSave>Save</button>
                <button onClick={cancel}><FaSave></FaSave>Cancel</button>
            </div>
        </form>
    )
}