import style from "./Navbar.module.css";
import { IoMdMenu } from "react-icons/io";
import { useDispatch} from "react-redux";
import { toggleCategory } from "../../Reducers/CategoryToggler";
import { setActivePage } from "../../Reducers/Activepage";

export default function Navbar() {
  const dispatch = useDispatch();
  const toggleSidebar=()=>{
    dispatch(toggleCategory());
  }

  const goToDefaultDashboard =()=>{
    dispatch(setActivePage('home'))
  }
  return (
    <div
      className={`${style.sidebaroutercontainer} d-flex align-items-center justify-content-around gap-3`}
    >
      <div className={`${style.logoContainer} col-4 col-lg-2 d-flex align-items-center justify-content-center gap-3`}>
         <span className="d-flex d-lg-none" onClick={toggleSidebar}><IoMdMenu></IoMdMenu></span>
         <a href="#/"><img src={process.env.PUBLIC_URL+"/images/RinzinBawaLogo.png"} alt="logo" onClick={goToDefaultDashboard}></img></a>
      </div>
      {/* <div className={`${style.searchContainer} col-12 d-flex  justify-content-center col-lg-4`}>
        <span><FaSearch></FaSearch></span>
        <input type="text" placeholder="Search"></input>
      </div> */}
      {/* <div className="col-4 col-lg-2 d-flex  justify-content-end gap-3 gap-md-5">
        <span className={`${style.aboutus} d-flex align-items-center `}>
          <a href="/#/aboutus">About us</a>
        </span>
        <span className={`${style.contactus} d-flex align-items-center `}>
          <a href="/#/contactus">Contact us</a>
        </span>
        
      </div>  */}
      {/* This is for profile and cart
       <div className="col-4 col-lg-2 d-flex  justify-content-end gap-3 gap-md-5">
        <span className={`${style.loginProfile} d-flex align-items-center `}>
        <CgProfile />
        </span>
        <div className={`${style.cart} d-flex align-items-center `}>
          <span
            className="d-flex align-items-center gap-2"
            onClick={showCart}
            name="cart"
          >
            <span className={`${style.cartIcon}`}><IoMdCart></IoMdCart><span
              className={`${style.noofitemincart} ${
                itemsInCart.cartItems.length == 0 ? "d-none" : ""
              }`}
            >
              {itemsInCart.cartItems.length}
            </span></span>
            <span className="d-none d-xl-flex">Cart</span>
          </span>
        </div>
      </div> */}
    </div>
  );
}
