import { useEffect, useState } from "react";
import style from "./Dashboard.module.css";
import { MdDelete, MdEdit } from "react-icons/md";
import { db } from "../../firebase";
import { collection, deleteDoc, doc,  getDocs, query, where } from "firebase/firestore";
import Newdashboard from "./Newdashboard.component";
import Updatedashboard from "./Updatedashboard.component";
export default function Dashboardmanager({ showLoading }) {
  const [itemsfound, setItems] = useState([]);
  const [activeComponent , setActiveComponent] = useState({page:'',uid:''});
  const handleSearch = async (event) => {
    if(event.currentTarget.value ===''){
        const item = await getDocs(collection(db, "dashboard"));
        setItems(item.docs.map(doc => ({
            id: doc.id, // Include the document ID
            ...doc.data() // Include the document data
        })));
        return;
    }
    if (event.key.toLowerCase() === "enter") {
        const productCollection = collection(db,'dashboard');
        const q = query(productCollection,where('uid','==',event.currentTarget.value));
        
        const item = await getDocs(q);
        setItems(item.docs.map(doc => ({
            id: doc.id, // Include the document ID
            ...doc.data() // Include the document data
            })));
        return;
    } 
  };
  const addNewProduct = ()=>{
    setActiveComponent({page:'newproduct',uid:''});
  }
  const updateProduct = (event)=>{
    event.stopPropagation();
    setActiveComponent({page:'updateproduct',uid:event.currentTarget.getAttribute('data-uid')});
  }
  const deleteProduct = async (event) =>{
    event.stopPropagation();
    await deleteDoc(doc(db, "dashboard",event.currentTarget.getAttribute('data-uid')));
    const item = await getDocs(collection(db, "dashboard"));
    setItems(item.docs.map(doc => ({
        id: doc.id, // Include the document ID
        ...doc.data() // Include the document data
    })));
    return;
  }
  useEffect(() => {
    getDocs(collection(db, "dashboard")).then((item)=>{
        setItems(item.docs.map(doc => ({
            id: doc.id, // Include the document ID
            ...doc.data() // Include the document data
        })));
    });
        
  }, [activeComponent]);
  const searchResult = (
    <div className={style.productmanager}>
      <div className={`${style.productactions} d-flex gap-2`}>
        <input
          type="text"
          onKeyUp={handleSearch}
          placeholder="Dashboard UID "
        ></input>
        <button onClick={addNewProduct}>New</button>
      </div>
      <div className={style.productactioncontainer}>
        {itemsfound.map((item) => {
          return(
            <div className={`${style.founditem} d-flex flex-wrap`}>
            <div className="col-6 d-flex flex-column">
              <span>{item.uid}</span>
            </div>
            <div
              className={`${style.founditemaction} col-6 d-flex gap-3 justify-content-end`}
            >
              <span data-uid={item.uid} onClick={updateProduct}>
                <MdEdit></MdEdit>
              </span>
              <span data-uid={item.uid} onClick={deleteProduct}>
                <MdDelete></MdDelete>
              </span>
            </div>
            <img className="col-12" src={item.photos[0].downloadURL} alt={item.uid}></img>
          </div>
          )
        })}
        <span className={itemsfound.length===0?'d-flex':'d-none'}>No item found with the search value</span>
      </div>
    </div>
  );
  return <div>{activeComponent.page===''?searchResult:(activeComponent.page==='newproduct'?<Newdashboard showLoading={showLoading} setActiveComponent={setActiveComponent}></Newdashboard>:<Updatedashboard showLoading={showLoading} uid={activeComponent.uid} setActiveComponent={setActiveComponent}></Updatedashboard>)}</div>;
}
